import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Bmob from 'hydrogen-js-sdk'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Bmob.initialize('61fb1f06cd8b9ba5', 'kqmapp')
Vue.prototype.Bmob = Bmob
